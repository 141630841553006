import { template as template_9e73537725dc47278e537f1282f69bbd } from "@ember/template-compiler";
const FKControlMenuContainer = template_9e73537725dc47278e537f1282f69bbd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
